export default {
    'section1.title': 'نجعل الامتثال سهلاً وفعالاً من حيث التكلفة. ',
    'section1.sub.title': 'منذ عام 2013 ، نقدم خدمات ومساعدة الشركات في مجال الأدوية والأغذية والأجهزة الطبية ومرافق الرعاية الصحية. لقد علمتنا تجربتنا أن النصيحة الجيدة تأتي من فهم أن هناك فرقًا بين كيفية كتابة القواعد وتفسيرها وإنفاذها',
    'section2.title': 'نظام التتبع الإلكتروني',
    'section2.sub1': 'أطلقت الهيئة العامة للغذاء والدواء نظام تتبع وتتبع الأدوية للمنتجات الصيدلانية (RSD) كواحدة من خططها للمساهمة في برنامج التحول الوطني 2020.',
    'section2.sub2': 'يهدف هذا البرنامج إلى تحقيق رؤية المملكة 2030 من خلال اعتماد تقنية جديدة لتتبع جميع الأدوية البشرية المسجلة المصنعة في السعودية العربية و مستوردة من الخارج.',
    'section2.sub3': 'تتبع وتتبع الأدوية يعزز دور الهيئة العامة للغذاء والدواء في حماية المجتمع وضمان سلامة جميع الأدوية من خلال معرفة مصدرها بدءًا من مرحلة التصنيع وحتى الاستهلاك.',
    'section3.title': 'أهداف تتبع مراقبة الجودة',
    'section3.sub1.title': 'منع الأدوية المزيفة',
    'section3.sub1': 'مراقبة عمليات سلسلة التوريد الكاملة. ضمان أن جميع الأدوية المباعة أو المستهلكة أصلية. إحصائيات موثوقة حول الأدوية الموجهة للتزييف. إحصائيات موثوقة حول مصدر بيع الأدوية المزيفة.',
    'section3.sub2.title': 'توافر الأدوية',
    'section3.sub2': 'تقليل الوقت المستغرق في توفير بيانات موثوقة. منصة سهلة للمريض لمعرفة معلومات الدواء عبر الهواتف الذكية. دعم الاستخدام الأفضل للمنتجات وتقليل الهدر',
    'section3.sub3.title': 'تحقيق مأمونية الدواء',
    'section3.sub3': 'توقف في الوقت الحقيقي عن الأدوية التي تم سحبها أو مع التحذيرات. تمكين المستهلك من التحقق من سلامة الدواء والإبلاغ عن أي آثار جانبية',
}


