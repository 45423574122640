<a class="overlay-sidebar-header" [class.overlay-sidebar-header-open]="openSide" (click)="closeOverlay()"></a>
<div class="responsive-btn">
    <a (click)="toggleSidebar()">
        <i class="fa fa-bars text-white" aria-hidden="true"></i>
    </a>
</div>
<div class="navbar m-l-auto" id="togglebtn" [class.openSidebar]="openSide">
    <div class="responsive-btn">
        <a (click)="toggleSidebar()">
            <h5>back</h5>
        </a>
    </div>
    <ul class="main-menu">
        <!-- 1st Level Menu -->
        <li *ngFor="let menuItem of menuItems" [class]="menuItem.megaMenu ? 'mega-menu' : ''">
            <!-- Sub -->
            <a href="javascript:void(0)" *ngIf="menuItem.type === 'sub'" class="dropdown"
               (click)="setActive(menuItem.title)">
                {{menuItem.title}}
            </a>
            <!-- Link -->
            <a routerLink="/" routerLinkActive="active" class="dropdown" *ngIf="menuItem.type === 'link'">
                {{menuItem.title}}
            </a>

            <!-- External Link -->
            <a href="javascript:void(0)" class="dropdown" *ngIf="menuItem.type === 'extLink'">
                {{menuItem.title}}
            </a>
            <!-- External Tab Link -->
            <a href="javascript:void(0)" *ngIf="menuItem.type === 'extTabLink'">
                <ng-container *ngIf="menuItem.title != 'Login'"><span>{{menuItem.title}}</span></ng-container>
                <ng-container *ngIf="menuItem.title === 'Login'"><a style="color: dodgerblue !important;"
                        href="http://mcchamawymcc-001-site1.itempurl.com" target="_blank">LOGIN</a>
                </ng-container>
            </a>

            <!-- Mega menu -->
            <div class="mega-menu-container" [class.opensubmenu]="isActive(menuItem.title)"
                 *ngIf="menuItem.megaMenu && menuItem.megaMenuType == 'small' || menuItem.megaMenuType == 'medium' || menuItem.megaMenuType == 'large'">
                <div class="container">
                    <div class="row">
                        <div [class]="menuItem.megaMenuType == 'small' ? 'col-lg-4' : menuItem.megaMenuType == 'medium' ? 'col-lg-3' : menuItem.megaMenuType =='large'? 'col' :'' "
                             *ngFor="let childrenItem of menuItem.children">
                            <div class="menu-container">
                                <a class="menu-head"
                                   (click)="setChildActive(childrenItem.title)">{{childrenItem.title}}</a>
                                <ul [class.menu-icon]="menuItem.title == 'Elements'"
                                    [class.openSubChildMenu]="ischildActive(childrenItem.title)">
                                    <li *ngFor="let subChildrenItem of childrenItem.children">
                                        <a routerLink="/" routerLinkActive="active"
                                           *ngIf="subChildrenItem.type === 'link'">
                                            <i class="icon-{{subChildrenItem.icon}}"></i>{{subChildrenItem.title}}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 2nd Level Menu -->
            <ul *ngIf="menuItem.children && !menuItem.megaMenu" [class.opensubmenu]="isActive(menuItem.title)">
                <!-- Simple Menu Start-->
                <li *ngFor="let childrenItem of menuItem.children" [class.sub-menu]="childrenItem.children">
                    <!-- Sub -->
                    <a href="javascript:void(0)" *ngIf="childrenItem.type === 'sub'"
                       (click)="setChildActive(childrenItem.title)">
                        {{childrenItem.title}}
                    </a>
                    <!-- Link -->
                    <a routerLink="/" routerLinkActive="active"
                       *ngIf="childrenItem.type === 'link' && !menuItem.megaMenu">
                        {{childrenItem.title}}
                    </a>
                    <!-- External Link -->
                    <a href="javascript:void(0)"
                       *ngIf="childrenItem.type === 'extLink' && !menuItem.megaMenu">
                        {{childrenItem.title}}
                    </a>
                    <!-- External Tab Link -->
                    <a href="javascript:void(0)" target="_blank"
                       *ngIf="childrenItem.type === 'extTabLink' && !menuItem.megaMenu">
                        {{childrenItem.title}}
                    </a>
                    <ul *ngIf="childrenItem.children" [class.openSubChildMenu]="ischildActive(childrenItem.title)">
                        <li *ngFor="let subChildrenItem of childrenItem.children">
                            <!-- Sub -->
                            <a href="javascript:void(0)" *ngIf="subChildrenItem.type === 'sub'">
                                {{subChildrenItem.title}}
                            </a>
                            <!-- Link -->
                            <a routerLink="/" routerLinkActive="active"
                               *ngIf="subChildrenItem.type === 'link' && !menuItem.megaMenu">
                                {{subChildrenItem.title}}
                            </a>
                            <!-- External Link -->
                            <a href="javascript:void(0)"
                               *ngIf="subChildrenItem.type === 'extLink' && !menuItem.megaMenu">
                                {{subChildrenItem.title}}
                            </a>
                            <!-- External Tab Link -->
                            <a href="javascript:void(0)" target="_blank"
                               *ngIf="subChildrenItem.type === 'extTabLink' && !menuItem.megaMenu">
                                {{subChildrenItem.title}}
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>

        </li>
    </ul>
</div>
  
