<!--header css start-->
<section class="saas2 header" id="home">
    <div class="saas2-content ">
        <div class="bg saas2-bg" [ngStyle]="{'background-image': 'url(assets/images/saas2/slider.png)'}">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="center-text">
                            <div class="">
                                <div class="header-text">
                                    <h1>{{'section1.title' | translate}}</h1>
                                </div>
                                <div class="header-sub-text">
                                    <p class="sub-para text-white">{{'section1.sub.title' | translate}}</p>
                                </div>
<!--                                <a class="btn btn-default primary-btn transparent">start now</a>-->
                            </div>
                        </div>
                    </div>
                    <div class="center-text set-abs">
                        <img style="height: 400px!important;" alt="" src="assets/images/img/Group 10887.png"
                             class="img-fluid img-dextop">
                    </div>
                </div>
            </div>

            <img alt="" src="assets/images/saas1/background2.png"
                 class="img-fluid set-abs background-animate">
            <img alt="" src="assets/images/saas2/header-icon/3.png"
                 class="img-fluid set-abs img1 move-up-down">
            <img alt="" src="assets/images/saas2/header-icon/2.png"
                 class="img-fluid set-abs img2 move-right-left">
            <img alt="" src="assets/images/saas2/header-icon/4.png"
                 class="img-fluid set-abs img3 move-up-down">
            <img alt="" src="assets/images/saas2/header-icon/5.png"
                 class="img-fluid set-abs img4 move-up-down">
            <img alt="" src="assets/images/saas2/header-icon/7.png"
                 class="img-fluid set-abs img5 move-right-left">
            <img alt="" src="assets/images/saas2/header-icon/9.png"
                 class="img-fluid set-abs img6 move-up-down">
            <img alt="" src="assets/images/saas2/header-icon/6.png"
                 class="img-fluid set-abs img7 move-up-down">
            <div class="set-abs round move-right-left">
                <img alt="" src="assets/images/saas2/header-icon/10.png" class="img-fluid img8">
                <div class="set-abs inner-circle">
                    <img alt="" src="assets/images/saas2/header-icon/8.png" class="img-fluid img9">
                </div>
            </div>
            <div class="center-content set-abs bottom-content">
                <div class="bottom">
                    <a class="down">
                        <img alt="" src="assets/images/saas2/header-icon/down.png" [scrollTo]="'#feaure'"
                             [scrollDuration]="1000" class="img-fluid">
                    </a>
                    <div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div>
    </div>
</section>
<!--header css end-->
