export default {
    'section1.title': 'We Make the Compliance Easy and Cost-Effective.',
    'section1.sub.title': 'Since 2013 we have been providing services and' +
        '                                        assisting companies in the Pharmaceutical, Food, Medical device and Heath Care' +
        '                                        facilities. Our experience has taught us that good advice comes from' +
        '                                        understanding that there is a difference between how the rules are written,' +
        '                                        interpreted and enforced.',
    'section2.sub1': 'The Saudi Food & Drug Authority initiated Drug Track and Trace System for pharmaceutical products (RSD) as one of its plans to contribute to the national transformation program 2020',
    'section2.sub2': 'This program aims to achieve the kingdom’s Vision 2030 by adopting a new technology for tracking all human registered drugs manufactured in Saudi \n' +
        'Arabia and imported from abroad.',
    'section2.sub3': 'Drug Track and Trace enhances Saudi Food & Drug Authority’s role in protecting society and guaranteeing the safety of all drugs by knowing their origin starting from manufacturing phase until consumption.',
    'section3.title': 'QC TRACK Targets',
    'section3.sub1.title': 'Preventing counterfeit drugs',
    'section3.sub1': 'Monitoring the fill supply chain operations. Guaranteeing that all sold or consumed drugs are genuine.' +
        ' Reliable statistics about targeted medication for counterfeit.' +
        ' Reliable statistics about source of selling counterfeit medication',
    'section3.sub2.title': 'Drug availability',
    'section3.sub2': 'Decrease the time consumed to provide reliable data. Easy platform for patient to know medication information via smartphones. Support for the best use of products and reduce waste.',
    'section3.sub3.title': 'Achieving drug safety',
    'section3.sub3': 'Real time stops of recalled medications or with warnings. Enabling the consumer to check safety of a drug and report any side effects.'


}


