<!--business css start-->
<section class="saas2 bg-gradient quick-sol"
         [ngStyle]="{'background-image': 'url(assets/images/saas2/background/quick-sol.png)'}">
    <div class="container set-center-div">
        <div class="row">
            <div class="col-lg-6">
                <div class="offers-container">
                    <h3 class="text-white offer-heading-text">{{'section3.title' | translate}}</h3>
                    <div class="bottom-border">
                    </div>
                    <div class="offers-content">
                        <div class="d-flex">
                            <i class="fa fa-check-circle" aria-hidden="true"></i>
                            <div>
                                <h4 class="offer-main-text text-white">{{'section3.sub1.title' | translate}}</h4>
                                <p class="offer-sub-text text-white">{{'section3.sub1' | translate}}</p>
                            </div>
                        </div>
                        <br>
                        <div class="d-flex">
                            <i class="fa fa-check-circle" aria-hidden="true"></i>
                            <div>
                                <h4 class="offer-main-text text-white">{{'section3.sub2.title' | translate}}</h4>
                                <p class="offer-sub-text text-white">{{'section3.sub2' | translate}}</p>
                            </div>
                        </div>
                        <br>

                        <div class="d-flex">
                            <i class="fa fa-check-circle" aria-hidden="true"></i>
                            <div>
                                <h4 class="offer-main-text text-white">{{'section3.sub3.title' | translate}}</h4>
                                <p class="offer-sub-text text-white">{{'section3.sub3' | translate}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="center-text side-img">
                <img alt="" src="assets/images/saas2/we-offer/img.png" class="img-fluid" data-tilt data-tilt-max="3"
                     data-tilt-speed="400" data-tilt-perspective="500">
            </div>
        </div>
    </div>
</section>
<!--business css end-->
