<!--feature css start-->
<section class="saas2 feature booking" id="feaure">
    <div class="container">
        <div class="row">
            <div class="col-md-10 offset-md-1">
                <div class="title">
                    <div class="main-title">
                        <h2>Electronic Tracking System</h2>
                    </div>
                    <div class="sub-title">
                        <!--                      <p class="sub-title-para">We believe we have created the most efficient SaaS landing page for-->
                        <!--                          your users.-->
                        <!--                          Landing page with features that will convince you to use it for your SaaS business.</p>-->
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-md-6">
                <div class="center-text justify-content-center">
                    <div class="image-container">
                        <img src="assets/images/img/img2.png" alt=""
                             class="img-fluid advance-feature-img">
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-md-5">
                <div class="feature-content">
                    <p class="feature-para">{{'section2.sub1' | translate}}</p>
                </div>
                <br>
                <div class="feature-content">
                    <p class="feature-para">{{'section2.sub2' | translate}}</p>
                </div>
                <br>

                <div class="feature-content">
                    <p class="feature-para">{{'section2.sub3' | translate}}</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!--feature css end-->
