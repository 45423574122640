import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {ModernSassComponent} from './layouts/modern-sass/modern-sass.component'
import {from} from 'rxjs';
import {HomeComponent} from './modules/home/home.component';
import {MainComponent} from './layouts/main/main.component';

export const routes: Routes = [
    {
        path: '',
        component: MainComponent,
        children: [
          {
            path: '',
            loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
          },
        ]
    },
    {
        path: 'modern-sass',
        component: ModernSassComponent
    },

];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        // preloadingStrategy: PreloadAllModules,
        anchorScrolling: 'enabled',
        scrollPositionRestoration: 'enabled',
        initialNavigation: 'enabled'
    })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
