import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-modern-sass-feature',
    templateUrl: './modern-sass-feature.component.html',
    styleUrls: ['./modern-sass-feature.component.scss']
})
export class ModernSassFeatureComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

    features = [
        {
            img: 'assets/images/saas2/advance-feature/1.png',
            title: 'Clean Design',
            description: 'The Saudi Food & Drug Authority initiated Drug Track and Trace System for pharmaceutical products (RSD) as one of its plans to contribute to the national transformation program 2020.'
        },
        {
            img: 'assets/images/saas2/advance-feature/2.png',
            title: 'Dedicated Support',
            description: 'This program aims to achieve the kingdom’s Vision 2030 by adopting a new technology for tracking all human registered drugs manufactured in Saudi \n' +
                'Arabia and imported from abroad.'
        },
        {
            img: 'assets/images/saas2/advance-feature/3.png',
            title: 'Easy Customiable',
            description: 'Drug Track and Trace enhances Saudi Food & Drug Authority’s role in protecting society and guaranteeing the safety of all drugs by knowing their origin starting from manufacturing phase until consumption.'
        },

    ]
}
