import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

// Menu
export interface Menu {
    path?: string;
    title?: string;
    type?: string;
    icon?: string;
    badgeType?: string;
    badgeValue?: string;
    active?: boolean;
    megaMenu?: boolean;
    megaMenuType?: string; // small, medium, large
    bookmark?: boolean;
    children?: Menu[];
}

@Injectable({
    providedIn: 'root'
})
export class NavService {

    constructor() {
    }

    MENUITEMS: Menu[] = [
        {
            title: 'Home', type: 'extTabLink',
        },
        {
            title: 'Screen Shot', type: 'extTabLink',
        },
        {
            title: 'Company Application', type: 'extTabLink',
        },
        {
            title: 'Contact Us', type: 'extTabLink',
        },
        {
            title: 'About Us', type: 'extTabLink'
        },
        {
            title: 'Login', type: 'extTabLink'
        },
    ];

    // Array
    items = new BehaviorSubject<Menu[]>(this.MENUITEMS);

}
