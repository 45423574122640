<!--slider css start-->
<section class="saas2 laptop-slider bg-gradient" id="screenshot"
         [ngStyle]="{'background-image': 'url(assets/images/saas2/background/screenshot-bg.png)'}">
    <div class="container-fluid">
        <div class="slider-container">
            <owl-carousel-o [options]="screenshotscarouselOptions" class="trainers-slider">
                <ng-container *ngFor="let screenshot of screenshots">
                    <ng-template carouselSlide class="item">
                        <div class="row">
                            <div class="col-md-10 offset-md-1">
                                <div class="title">
                                    <div class="main-title">
                                        <h2 class="text-white">{{screenshot.title}}</h2>
                                    </div>
<!--                                    <div class="sub-title">-->
<!--                                        <p class="sub-title-para text-white">-->
<!--                                            {{screenshot.text}}</p>-->
<!--                                    </div>-->
                                </div>
                            </div>
                        </div>
                        <img [src]="screenshot.img" alt="" class="img-fluid">
                    </ng-template>
                </ng-container>
            </owl-carousel-o>
        </div>
    </div>
</section>
<!--slider css start-->
