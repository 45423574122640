<app-loader></app-loader>
<router-outlet></router-outlet>
<div class="theme-pannel-main">
    <ul>
        <li id="rtl_btn" *ngIf="customize.data.settings.layout_type == 'ltr'" (click)="customizeLayoutType('rtl')"><a href="javascript:void(0)" class="btn setting_btn"><span class="rtl-txt">Rtl</span></a></li>
        <li id="ltr_btn" *ngIf="customize.data.settings.layout_type == 'rtl'" (click)="customizeLayoutType('ltr')"><a href="javascript:void(0)" class="btn setting_btn"><span class="rtl-txt">Ltr</span></a></li>
        <li class="sidebar-btn dark-light-btn" *ngIf="customize.data.settings.layout_version == 'dark'" (click)="customizeLayoutVersion('light')">
            <a href="javascript:void(0)" class="dark-light"><span class="theme-layout-version">Light</span></a>
        </li>
        <li class="sidebar-btn dark-light-btn"  *ngIf="customize.data.settings.layout_version == 'light'" (click)="customizeLayoutVersion('dark')">
            <a href="javascript:void(0)" class="dark-light"><span class="theme-layout-version">Dark</span></a>
        </li>
    </ul>
</div>
