import {Component} from '@angular/core';
import {CustomizerService} from './shared/service/customizer.service';
import {NavigationEnd, Router} from '@angular/router';
import english from './locales/en-US';
import arabic from './locales/ar-EG';
import {TranslateService} from '@ngx-translate/core';

const locales = {
    ltr: english,
    rtl: arabic,
};

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    public url: any;
    title = 'Unice';
    public layoutType: string = 'dark'


    constructor(public customize: CustomizerService, private router: Router, private  translate: TranslateService,
    ) {
        Object.keys(locales).forEach(locale => {
            console.log(locale)
            translate.setTranslation(locale, locales[locale]);
        });
        translate.setDefaultLang(localStorage.getItem('layoutType') || 'ltr');
        if (localStorage.getItem('layoutType')) {
            this.customizeLayoutType(localStorage.getItem('layoutType'));
        } else {
            this.customizeLayoutType('ltr')
        }
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.url = event.url;
            }
        });
    }

    customizeLayoutVersion(val) {
        this.customize.setLayoutVersion(val)
        this.layoutType = val
    }

    customizeLayoutType(val) {
        console.log(val)
        this.customize.setLayoutType(val);
        this.layoutType = val;
        this.translate.use(val);

    }


}
