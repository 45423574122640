<!--Nav start-->
<header class="saas2 loding-header nav-abs custom-scroll">
        <div class="row">
            <div class="col">
                <nav>
                    <a href="#" class="d-inline-block m-r-auto">
                        <img style="width: 50%" src="assets/images/qc/qc-01.png" alt="" class="img-fluid">
                    </a>
                    <app-menu></app-menu>
                </nav>
            </div>
    </div>
</header>
<!--Nav end-->
