<!--footer start-->
<footer class="saas2 footer2" id="contact" [ngStyle]="{'background-image': 'url(assets/images/saas2/footer.png)', 'background-repeat': 'no-repeat', 'background-position': 'top'}">
    <div class="container footer-padding">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="logo-sec">
                    <div class="footer-title mobile-title p-t-0">
                        <h3 class="text-white">About Us</h3>
                    </div>
                    <div class="footer-contant">
                        <img src="assets/images/qc/qc-01.png" alt="" class="img-fluid footer-logo">
                        <div class="footer-para">
                            <h6 class="text-white para-address">QC Tracks</h6>
                            <h6 class="text-white para-address">Riyadh, Saudi Arabia </h6>
                            <h6 class="text-white para-address">920011898</h6>
                            <h6 class="text-white para-address">info@qctracks.com</h6>
                        </div>
                        <ul class="d-d-flex footer-social social">
                            <li class="footer-social-list">
                                <a href="#"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                            </li>
                            <li class="footer-social-list">
                                <a href="#"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                            </li>
                            <li class="footer-social-list">
                                <a href="#"><i class="fa fa-google" aria-hidden="true"></i></a>
                            </li>
                            <li class="footer-social-list">
                                <a target="_blank" href="https://wa.me/+9660551618325/?text="><i class="fa fa-whatsapp" aria-hidden="true"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <!-- <div class="col-lg-3 col-md-6 col-sm-12">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">Product</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">Product</h5>
                    <div>
                        <ul class="footer-lists">
                            <li class="">
                                <a href="#">Discover features</a>
                            </li>
                            <li class="">
                                <a href="#">CMS integration</a>
                            </li>
                            <li class="">
                                <a href="#">Customers</a>
                            </li>
                            <li class="">
                                <a href="#">Free trials and demo</a>
                            </li>
                            <li class="">
                                <a href="#">What Next ?</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div> -->

            <div class="col-lg-2 col-md-6 col-sm-12">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">Company</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">Company</h5>
                    <div>
                        <ul class="footer-lists">
                            <li class="">
                                <a href="#">About us</a>
                            </li>
                            <li class="">
                                <a href="#">About security</a>
                            </li>
                            <li class="">
                                <a href="#">User guide</a>
                            </li>
                            <li class="">
                                <a href="#">Terms of srvices</a>
                            </li>
                            <li class="">
                                <a href="#">Privact policy</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- <div class="col-lg-3 col-md-6 col-sm-12">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">Subscribe our newsletter</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">Subscribe our newsletter</h5>
                    <div class="form-group">
                        <input type="text" class="form-control" id="usr" placeholder="email address" />
                        <a href=""><i class="fa fa-paper-plane" aria-hidden="true"></i></a>
                    </div>
                    <div>
                        <h4 class="text-white unice-text">unice</h4>
                        <h6 class="text-white unice-copyright-text">© 2018_unice</h6>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</footer>
<!--footer end-->
<app-tap-to-top></app-tap-to-top>