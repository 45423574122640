import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-modern-sass-screenshots',
    templateUrl: './modern-sass-screenshots.component.html',
    styleUrls: ['./modern-sass-screenshots.component.scss']
})
export class ModernSassScreenshotsComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

    screenshots =
        [
            {
                title: 'Sign Up',
                img: 'assets/images/img/Web 1920 – 8.png',
                text: 'We believe we have created the most efficient SaaS landing page for your users. Landing page with features that will convince you to use it for your SaaS business.'
            },
            {
                title: 'Dash Board',

                img: 'assets/images/img/Web 1920 – 14.png',
                text: 'We believe we have created the most efficient SaaS landing page for your users. Landing page with features that will convince you to use it for your SaaS business.'

            },
            {
                title: 'Profile Company',
                img: 'assets/images/img/Web 1920 – 15.png',
                text: 'We believe we have created the most efficient SaaS landing page for your users. Landing page with features that will convince you to use it for your SaaS business.'

            },
            // {
            //     title: 'Company  Requests',
            //     img: 'assets/images/img/Web 1920 – 16.png',
            //     text: 'We believe we have created the most efficient SaaS landing page for your users. Landing page with features that will convince you to use it for your SaaS business.'
            // },
        ]

    screenshotscarouselOptions = {
        items: 3,
        margin: 0,
        autoHeight: true,
        nav: false,
        autoplay: false,
        center: true,
        slideSpeed: 300,
        paginationSpeed: 400,
        dots: true,
        loop: true,
        responsive: {
            0: {
                items: 1
            },
            992: {
                items: 3
            }
        }
    }

}
