import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-modern-sass-business',
    templateUrl: './modern-sass-business.component.html',
    styleUrls: ['./modern-sass-business.component.scss']
})
export class ModernSassBusinessComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

    business = [
        {
            title: 'Preventing counterfeit drugs',
            description: 'Monitoring the fill supply chain operations. Guaranteeing that all sold or consumed drugs are genuine.' +
                ' Reliable statistics about targeted medication for counterfeit.' +
                ' Reliable statistics about source of selling counterfeit medication'
        },
        {
            title: 'Drug availability',
            description: 'Decrease the time consumed to provide reliable data. Easy platform for patient to know medication information via smartphones. Support for the best use of products and reduce waste.'
        },
        {
            title: 'Achieving drug safety',
            description: 'Real time stops of recalled medications or with warnings. Enabling the consumer to check safety of a drug and report any side effects.'
        }
    ]
}
